@tailwind base;
@tailwind components;
@tailwind utilities;

/* Remove the line trying to import grid-pattern.png */
/* Add any other custom styles below */

@layer components {
  .nav-link {
    @apply text-teal-100 hover:text-white transition-colors duration-300 
           relative after:absolute after:bottom-0 after:left-0 after:w-full 
           after:h-0.5 after:bg-teal-400 after:scale-x-0 hover:after:scale-x-100 
           after:transition-transform after:duration-300;
  }

  .footer-link {
    @apply text-gray-300 hover:text-teal-300 transition-colors duration-300 block;
  }

  .chip-button {
    @apply flex items-center space-x-2 px-4 py-2 rounded-full bg-white 
           border border-gray-200 text-sm text-gray-700 hover:bg-gray-50 
           transition-all duration-200 shadow-sm;
  }
}

/* Custom scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #CBD5E0 #EDF2F7;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #EDF2F7;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 3px;
}
