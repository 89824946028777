.blog-post-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.back-link {
  display: inline-flex;
  align-items: center;
  color: #3182ce;
  text-decoration: none;
  margin-bottom: 2rem;
  font-weight: 500;
}

.blog-post {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.featured-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 400px;
}

.blog-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2d3748;
  margin: 1.5rem 0 1rem;
  padding: 0 2rem;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  color: #718096;
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  padding: 0 2rem;
}

.blog-excerpt {
  font-size: 1.2rem;
  color: #4a5568;
  margin-bottom: 2rem;
  padding: 0 2rem;
  font-style: italic;
}

.table-of-contents {
  background-color: #f7fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 0 2rem 2rem;
}

.table-of-contents ul {
  list-style-type: none;
  padding-left: 0;
}

.table-of-contents a {
  color: #4a5568;
  text-decoration: none;
  transition: color 0.2s ease;
}

.table-of-contents a:hover {
  color: #3182ce;
}

.toc-level-2 {
  margin-left: 1rem;
}

.toc-level-3 {
  margin-left: 2rem;
}

.blog-content {
  padding: 0 2rem;
}

.blog-content h2 {
  font-size: 1.8rem;
  color: #2d3748;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.blog-content h3 {
  font-size: 1.5rem;
  color: #4a5568;
  margin-top: 2rem;
  margin-bottom: 0.75rem;
}

.blog-content .indented-heading {
  padding-left: 1.5rem;
}

.blog-content p {
  margin-bottom: 1.5rem;
  line-height: 1.7;
  color: #2d3748;
}

.blog-content h3 + p,
.blog-content h3 + ul,
.blog-content h3 + ol {
  padding-left: 1.5rem;
}

.blog-content ul, .blog-content ol {
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}

.blog-content li {
  margin-bottom: 0.5rem;
}

.blog-tags {
  margin-top: 3rem;
  padding: 0 2rem 2rem;
}

.blog-tags h2 {
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 1rem;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.tag {
  background-color: #e2e8f0;
  color: #4a5568;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.tag:hover {
  background-color: #cbd5e0;
  color: #2d3748;
}

.loading-spinner, .error-message, .not-found-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 1.2rem;
  color: #4a5568;
}

.loading-spinner::after {
  content: "";
  width: 50px;
  height: 50px;
  border: 5px solid #e2e8f0;
  border-top: 5px solid #3182ce;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}