/* BlogEditor.css */

.blog-content h1 {
    font-size: 2.5em;
    font-weight: bold;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .blog-content h2 {
    font-size: 2em;
    font-weight: bold;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .blog-content h3 {
    font-size: 1.75em;
    font-weight: bold;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .blog-content p {
    margin-bottom: 1em;
    line-height: 1.6;
  }
  
  .blog-content ul, .blog-content ol {
    margin-bottom: 1em;
    padding-left: 2em;
  }
  
  .blog-content ul {
    list-style-type: disc;
  }
  
  .blog-content ol {
    list-style-type: decimal;
  }
  
  .blog-content li {
    margin-bottom: 0.5em;
  }
  
  .blog-content blockquote {
    border-left: 4px solid #e2e8f0;
    padding-left: 1em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    color: #4a5568;
  }
  
  .blog-content pre {
    background-color: #f7fafc;
    padding: 1em;
    border-radius: 0.25em;
    overflow-x: auto;
    margin-bottom: 1em;
  }
  
  .blog-content code {
    background-color: #edf2f7;
    padding: 0.2em 0.4em;
    border-radius: 0.25em;
    font-family: monospace;
  }
  
  .blog-content img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1em;
  }
  
  .blog-content iframe {
    max-width: 100%;
    margin-bottom: 1em;
  }
  
  .blog-content a {
    color: #4299e1;
    text-decoration: underline;
  }
  
  .blog-content a:hover {
    color: #2b6cb0;
  }
  
  .blog-content table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
  }
  
  .blog-content th, .blog-content td {
    border: 1px solid #e2e8f0;
    padding: 0.5em;
  }
  
  .blog-content th {
    background-color: #f7fafc;
    font-weight: bold;
  }